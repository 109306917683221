import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "When to Apply for Jobs: best & worst times",
  "author": "Purepost",
  "date": "2018-04-18T05:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "when-to-apply-for-jobs-best-worst-times",
  "draft": false,
  "meta_title": "When to Apply for Jobs: best & worst times",
  "cover": "../../images/wp_blog_images/GuideOn_macbook_iphone-1024x680.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As a member of the U.S. Military, there is a PCS pattern that occurs around the May to August timeframe. In some cases, you could be transitioning out of the Army during these peak times.`}</p>
    <p>{`Starting your career exploration before your last day in the Army is vital to landing a job at the right time. Depending on the industry you choose to enter, certain times of year might not be the best hiring season for a company. As you review these dates, start planning ahead - it's never too early to apply for a job.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1024px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.5625%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAH/2gAMAwEAAhADEAAAAaoTqteQGn//xAAbEAEAAgIDAAAAAAAAAAAAAAACAAEDEhETIf/aAAgBAQABBQI7KdO14hYBXuNXa4n/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEjH/2gAIAQMBAT8B1L//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/Adhb/8QAGhAAAgIDAAAAAAAAAAAAAAAAABEBECFRYf/aAAgBAQAGPwLpqRCFOa//xAAcEAACAgMBAQAAAAAAAAAAAAABEQAhMUFRccH/2gAIAQEAAT8hLaE5yXssvcfABtsQ30EPkgti4oxXk//aAAwDAQACAAMAAAAQj+//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ENF0g3//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QSsy1u//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWGBsf/aAAgBAQABPxBPN3BzBRXghcfI8erCiJGhr4SkivmVuz3OBYHSQn//2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "GuideOn macbook iphone 1024x680",
        "title": "GuideOn macbook iphone 1024x680",
        "src": "/static/017090985688997bc73b8a98777f75c1/72e01/GuideOn_macbook_iphone-1024x680.jpg",
        "srcSet": ["/static/017090985688997bc73b8a98777f75c1/cb69c/GuideOn_macbook_iphone-1024x680.jpg 320w", "/static/017090985688997bc73b8a98777f75c1/c08c5/GuideOn_macbook_iphone-1024x680.jpg 640w", "/static/017090985688997bc73b8a98777f75c1/72e01/GuideOn_macbook_iphone-1024x680.jpg 1024w"],
        "sizes": "(max-width: 1024px) 100vw, 1024px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p><strong parentName="p">{`Best times to Job Hunt:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Start of the Year`}</strong>{`-`}{` Most hiring managers may take extended holiday breaks, so if you are leaving the Army in December, note that you may not hear back until the middle of January. If a department receives their new budget this time of year, they are ready to hire people to support their annual goals. This time period of receiving a new budget and needing to fill positions is known as the “hiring push”. Keep in mind, the turnaround time for responses could be a few days to a few weeks in any case.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`September/October-`}</strong>{` Post-summer vacations, managers are ready to fill remaining positions for the year in preparation for the holiday season. Turnaround time for interviews may be faster than the beginning of the year due to deadlines.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Worst times to Job Hunt:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`June thru August`}</strong>{`-`}{` After the “hiring push” in the spring, things slow down for hiring which is just a natural pattern. If interviewing members of the team are away for summer vacations, it could take a longer time to hear back and be interviewed. The average time it could take for an interview could be 25 days, but it could take several weeks during a slow period.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`November/December-`}</strong>{` If you’re starting to check out due to the holiday season, it’s likely the hiring managers are too. That doesn’t mean people aren’t hiring, but with fiscal year budgets running out and holiday schedules, you will see a dip in job postings. Don’t expect to hear back from many postings between December 20-January 10 unless the jobs are for immediate hires. Seasonal jobs are just picking up in late November and early December, but some could become permanent.`}</p>
      </li>
    </ul>
    <p>{`Job hiring and workplace trends are ever-changing in the civilian market. A Performance Evaluation is not taken with you to each job as in the Army, but you can use that information with your GuideOn resume, in your cover letters, and as fuel in your interviews to quantify your abilities and responsibilities. Network and stay up-to-date on the `}<a parentName="p" {...{
        "href": "http://www.forbes.com/sites/danschawbel/2015/11/01/10-workplace-trends-for-2016/"
      }}>{`latest trends`}</a>{` when possible, this will give you peace of mind if you are waiting to hear back from recruiters and managers.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`References:`}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "http://careersidekick.com/the-two-best-and-worst-times-of-the-year-for-job-hunting/"
      }}>{`http://careersidekick.com/the-two-best-and-worst-times-of-the-year-for-job-hunting/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.ziprecruiter.com/blog/the-biggest-job-search-trends-for-2015/"
      }}>{`https://www.ziprecruiter.com/blog/the-biggest-job-search-trends-for-2015/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      